.Dashboard {
  .dashboard-intro {
    background-color: #90cccc;
    max-width: 511px;
    padding: 0 1rem 4rem 1rem;

    margin: 0 auto;
    p {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 1.6rem;
      line-height: 2.6rem;
      margin-bottom: 2rem;
      color: #2a2a2a;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .finished-status {
    padding-left: 46px;
    padding-top: 28px;
    padding-right: 10%;
    padding-bottom: 4rem;
    p {
      text-align: left;
    }
    @media only screen and (max-width: 768px) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
  div.separator {
    background-image: url('../../assets/dashboard/dashboard-separator.svg');
    background-repeat: repeat-x;
    text-align: center;
    background-position: center;
    margin-bottom: 4.8rem;
    padding-top: 2.8rem;
  }
  .small {
    margin-top: 1rem;
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.8rem;

    text-align: center;
  }
  ul.items {
    list-style-type: none;
    padding: 0;
    clear: both;

    li {
      overflow-y: hidden;

      &.disabled {
        .circle,
        .time,
        .title,
        .description {
          opacity: 0.5;
        }
      }
      &.disabled,
      &.done {
        .btn-container {
          cursor: default;

          &:hover {
            background: transparent;
          }
        }
      }

      &:first-child {
        .btn-container > .graphics > div.rect {
          top: 50%;
        }
      }
      &:last-child {
        .btn-container > .graphics > div.rect {
          top: -175px;
        }
      }

      div.item {
        position: relative;
      }

      div.subMenu ul svg {
        margin: 0 1.2rem 0 0;
      }

      .btn-container {
        padding-left: 46px;

        padding-top: 28px;
        display: flex;
        cursor: pointer;
        margin-left: 0px;
        margin-left: 0px;
        padding-right: 10%;
        justify-content: space-between;

        .title {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 13px;

          display: flex;
          align-items: center;
          text-transform: uppercase;

          color: #fc9090;
        }

        .description {
          font-family: 'Playfair Display';
          font-style: normal;
          font-weight: bold;
          font-size: 1.8rem;
          line-height: 2.6rem;

          color: #2a2a2a;
        }
        .graphics {
          width: 5.6rem;
          flex-grow: 0;
          flex-shrink: 0;
          position: relative;

          div.circle {
            position: absolute;
            width: 24px;
            height: 24px;
            z-index: 10;
            top: 50%;
            margin-top: -16px;
            left: 0px;
          }
          div.rect {
            position: absolute;
            width: 24px;
            height: 200px;
            left: 0;
            top: -40px;
            background-color: rgba(0, 132, 255, 0.1);
          }
        }

        > a,
        > div {
          color: #000;
          text-align: left;

          white-space: pre-wrap;
          padding-top: 0;
          margin-bottom: 3.6rem;
          font-size: 1.8rem;
        }
        > div:nth-of-type(2) {
          flex-grow: 2;
        }

        .time {
          text-align: right;
          flex-shrink: 0;

          span {
            font-size: 10px;
            font-style: italic;
            padding-left: 3px;
            display: block;
          }
          @media only screen and (max-width: 768px) {
            padding-top: 1rem;
          }
        }
        @media only screen and (max-width: 768px) {
          padding-left: 1.5rem;
          padding-right: 1.5rem;
        }
      }

      .btn-container:hover {
        background-color: #fff;
        text-decoration: none;
      }
    }
  }
}

.hasAlert .Dashboard .dashboard-intro {
  padding-top: 1rem;
  padding-bottom: 2rem;
}
