// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $color;
    -moz-box-shadow: inset $top $left $blur $color;
    box-shadow: inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

/* create classes prefixed with the theme for properties for classes using themify */
@mixin themify($themes: $themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}
