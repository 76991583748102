@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';

@import 'components/synapse_form_wrapper/synapse-form-wrapper';
@import 'components/synapse_form_wrapper/overrides';
@import 'components/intro';
@import 'components/dashboard';
@import 'components/consent';
@import 'components/static';
@import 'components/floating-toolbar';
@import 'material-overrides';
@import 'forms';
@import 'mobile' screen and (max-width: 768px);

html {
  font-size: 62.5%; /* 62.5% of 16px = 10px */
}

body {
  margin: 0;
  font-family: 'Open Sans', serif, 'Lato', 'Roboto', Helvetica, Arial;
  background: #efefef;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Playfair Display', serif, 'Lato', 'Roboto', Helvetica, Arial;
  font-style: normal;
  color: #2a2a2a;
  font-weight: bold;
}

.print-only {
  display: none !important;
}

@media (min-width: 600px) {
  h1,
  h2 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  h3,
  h4 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  h1 {
    font-size: 40px;
    line-height: 51px;
  }
  h2 {
    font-size: 32px;
    line-height: 37px;
  }
  h3 {
    font-size: 24px;
    line-height: 30px;
  }
  h4 {
    font-size: 18px;
    line-height: 25px;
  }
  h5 {
    font-size: 14px;
    line-height: 22px;
  }
}

@media (max-width: 599px) {
  h1,
  h2 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  h3,
  h4 {
    margin-top: 7px;
    margin-bottom: 7px;
  }
  h1 {
    font-size: 24px;
    line-height: 30px;
  }
  h2 {
    font-size: 22px;
    line-height: 28px;
  }
  h3 {
    font-size: 20px;
    line-height: 26px;
  }
}

.MuiCardContent-root {
  h1:first-child,
  h2:first-child {
    margin-top: 2rem;
  }
}

p {
  color: #2a2a2a;
  font-size: 16px;
  line-height: 25px;
}

a {
  color: #0085ff;
  text-decoration: none;
  cursor: pointer;
}

#root .margin-top-std {
  margin-top: 4rem;
}

#root .margin-bottom-std {
  margin-bottom: 4rem;
}

#root .wideButton {
  padding: 0px 45px;
}

.cookiesBanner {
  background: #0085ff;
  color: white;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  font-size: 15px;
  padding: 20px 20px;
  // stick to bottom
  position: fixed;
  bottom: 15px;
  z-index: 6;
  width: 100%;

  a {
    color: white;
  }
  button {
    height: 33px;
    margin-left: 10px;
  }
}

div.intro {
  background-color: #0084ff;
  color: #fff;
  font-size: 1.6em;
  line-height: 1.8rem;
  padding: 3.2rem 4rem;

  p,
  span {
    color: #fff;
  }

  h1:first-child,
  h2:first-child {
    margin-top: 4.8rem;
  }
}

// material ui "lab" component override is challenging: https://github.com/mui-org/material-ui/issues/19427
// overriding the style here:

#root .MuiAlert-filledError {
  border-radius: 0;
  background-color: #fc9090;
  color: #000000;
  padding: 20px 30px;
  border-bottom: 1px rgb(46, 46, 46) solid;
}
#root .MuiAlert-filledInfo {
  border-radius: 0;
  background-color: #0084ff;
  padding: 20px 30px;
  border-bottom: 1px rgb(46, 46, 46) solid;
}

// if a button is in a link, do not decorate the text on hover
#root a:hover > button {
  text-decoration: 'none';
}

.partialGreen {
  background-image: url('../assets/dashboard/bg_rectangle_color.svg');
  background-repeat: repeat-x;
}

button.btnVerticallySpaced, div.btnVerticallySpaced{
  margin: 30px auto;
  @media (min-width: 961px) {
    margin: 35px auto;
  }
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .print-only {
    display: block !important;
  }
}
