.About,
.FAQs,
.Team,
.Contact,
.Home {
  padding-top: 8rem;
  /* p{
    margin-left: 20%;
    margin-right: 10%;
    font-family: georgia;
    }*/
}
