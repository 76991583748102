.Consent {
  h2 {
    font-size: 2.4rem;
    font-family: 'Playfair Display';
    font-weight: 700;
    margin-bottom: 3.2rem;
    line-height: 3rem;
    color: #2a2a2a;
  }

  p {
    color: #3a3a3a;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.6rem;
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  li {
    padding-bottom: 1rem;
    &:last-child {
      padding-bottom: 0;
    }
  }

  .consentConfirmation {
    text-align: center;
    margin-bottom: 3.2rem;
    margin-top: 6rem;
  }

  .checkbox--indented p {
    padding-top: 0;
  }

  .Consent__form {
    .radiobuttons .MuiFormControlLabel-root {
      span {
        font-size: 1.8rem;
      }
    }
  }

  &__inset {
    margin-left: 5%;
  }

  .MuiCheckbox-root {
    padding-left: 0;
  }

  .page-numbers {
    color: #2a2a2a;
    font-family: 'Playfair Display';
    font-size: 1.4rem;
    font-weight: 700;
  }

  .icon-top {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 4.5rem;
  }
  .quizAnswer {
    margin: 20px;
  }

  .MuiFormControlLabel-root.success .MuiRadio-root.Mui-checked {
    color: #0085ff;
    // background-color: rgb(232, 244, 253); // default background color (MuiAlert-standardInfo)
  }
  .MuiFormControlLabel-root.error .MuiRadio-root.Mui-checked {
    color: #f44336; // default error color
    // background-color: rgb(253, 236, 234); //default background color (MuiAlert-standardError)
  }

  .navButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
    width: 100%;

    button {
      width: 92px;
    }
  }
}

.learnMoreClickableElement {
  cursor: pointer;
}

.learnMoreToggle {
  border-bottom: 1px solid #ddd;
  padding-bottom: 3px;

  margin-left: 0;
  text-align: right;
  margin-bottom: 4rem;
  cursor: pointer;
}

.learnLessToggle {
  margin-bottom: 4rem;
  margin-top: 2rem;
  border-bottom: 1px solid #ddd;

  justify-content: space-between;
  align-items: flex-end;
  div {
    padding-bottom: 2.4rem;
  }
  button {
    padding: 5px 0 5px 0;
    border: none;
    background: transparent;
  }
}

@media only screen and (max-width: 768px) {
}
