.form-group {
  margin-bottom: 4.5rem;
}

.input--padded {
  padding: 20px;
}

.input--min-padded {
  padding: 5px;
}

.tabbedField {
  background: #f8f8f8;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  padding-bottom: 3.2rem;
  margin-bottom: 0;
}

.buttons--action {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  align-items: center;
}

.buttons--vertical-stack {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  button {
    margin-bottom: 10px;
  }
}

.checkbox--nopad {
  .checkbox label {
    padding-left: 0;
  }
}

.checkbox--indented {
  display: flex;
  align-items: flex-start;
  padding-top: 16px;

  p {
    margin: 0;
    padding-top: 9px;
  }
}

label.block--dark {
  background: #efefef;
  color: #0084ff;

  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  text-align: center;
  padding: 1rem;
  border-bottom: 1px solid #0084ff;
  width: 100%;
}

.error {
  color: #ff6d6d;
}
.modal-body {
  padding: 100px 50px;
}
