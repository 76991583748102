.FloatingToolbar {
  position: fixed; /* Make it stick/fixed */
  top: 0px; /* Stay on top */

  transition: top 0.3s; /* Transition effect when sliding down (and up) */

  position: fixed;
  text-align: center;
  padding: 10px;

  right: 0;
  left: 0;
  width: initial;
  color: #f2f2f2;
  background-color: #2e2e2e;
  height: 56px;
  z-index: 10;

  transition: top 0.3s;
  a,
  a:hover,
  a:visited {
    color: #f2f2f2;
  }
  &__toggle {
    display: 'flex';
    justify-content: 'center';
    &__text {
      align-self: center;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: normal;
      color: #f5f5f5;
      font-size: 1.4rem;
      line-height: 20px;
      &:hover,
      &:active {
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
}
