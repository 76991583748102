.theme-crc .SRC-ReactJsonForm {
  $font-size: 1.6rem;
  $red-error: #ff6d6d;
  $red-error2: #ff6d6d;
  $left-pad: 1.5rem;
  $primary-blue: #0084ff;
  $gray-bg: #fcfcfc;
  $text-dark: #2a2a2a;

  @mixin label_level2 {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #2e2e2e;
  }

  @mixin subfield_inverted {
    label {
      @include label_level2;
      margin-bottom: 1rem;
    }
    margin-top: -5px;
    padding: 0rem 1rem 2rem 6rem;
    margin-right: -1.5rem;
    background-color: $gray-bg;

    margin-bottom: 0.5rem;
    label {
      margin-left: 0;
    }
  }

  @mixin control_label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 1rem;
    width: auto;
  }

  @mixin field-description {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2rem;
  }

  .MuiCard-root {
    padding-bottom: 2rem;
    max-width: 360px;
  }

  .inherit .MuiCard-root {
    max-width: 511px;
  }

  margin-right: 0;

  span {
    font-family: 'Open Sans';
  }

  legend {
    font-weight: bold;
    margin-left: $left-pad;
  }

  #root.fieldset {
    padding-right: 1.5rem;
  }

  .field-radio-group,
  div.checkboxes {
    margin-right: -1.5rem;
  }

  .form-group .radiorange {
    margin-right: -1.5rem;
    padding: 2rem 1.5rem 2rem 0;
    background-color: #fff;
    margin-bottom: 8px;
    margin-top: -6px;
  }

  .form-group.field-boolean {
    margin-right: -1.5rem;
  }
  .notification-area {
    margin-left: 1.5rem;
    color: $primary-blue;
  }

  .btn-clear {
    background-color: $primary-blue;
    border: none;
  }

  .paging {
    margin: 1rem 2rem 2rem 0;
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.3rem;
    color: $text-dark;
  }

  .padded-panel {
    padding: 0 1.5rem 0 $left-pad;
  }

  label.control-label {
    @include control_label;

    margin-left: $left-pad;
    margin-right: 1rem;
  }

  .field-description {
    @include field-description;
  }

  .right-top-actions {
    display: none;
  }

  .form-group {
    margin-bottom: 2rem;
  }

  .scroll-area {
    overflow-x: hidden;
    padding: 0;
  }
  .field-array {
    legend {
      font-size: 1.6rem;
      margin-bottom: 2rem;
    }
    .array-item {
      position: relative;
      margin: 0;
      padding: 0;

      border: none;
      .array-item-toolbox {
        i.glyphicon {
          background-color: $primary-blue;
        }

        .array-item-remove::after {
          display: none;
        }
      }
      .col-xs-3.array-item-add {
        position: static;
      }
      .col-xs-9 {
        width: 100%;
        padding: 0;
      }
      .col-xs-3 {
        width: auto;
        position: absolute;
        top: -0.7rem;
        right: -3%;
        padding: 0;
        margin: 0;
      }
    }
  }
  .submission-header {
    max-width: 360px;
    margin: 0 auto;
    h2 {
      text-align: left;
      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: bold;
      font-size: 2.4rem;
      line-height: 3rem;
      margin-top: 0px;
      margin-bottom: 2.4rem;
    }
    .surveyIntro {
      margin: 0 0 2.4rem 0;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: normal;

      font-size: 16px;
      line-height: 25px;
      color: $text-dark;
    }
  }

  .inherit .submission-header {
    max-width: 511px;
  }

  //submit button
  .MuiButtonBase-root.submit {
    padding-left: 3.2rem;
    padding-right: 3.2rem;

    span {
      font-size: 1.6rem;
      font-weight: bold;
    }
  }

  .form-group.field.text {
    padding-left: $left-pad;
    label {
      margin-left: 0;
    }
    &.short {
      input[type='text'],
      input[type='number'] {
        width: 50%;
      }
    }
  }

  .subfields {
    legend {
      @include control_label;
      margin-bottom: 1rem;
      display: inline-block;
      max-width: 100%;
    }
    .form-group {
      padding-left: $left-pad;
      padding-bottom: 2rem;
      margin-right: -1.5rem;

      margin-bottom: 0;
      background-color: #fcfcfc;
      > label {
        @include label_level2;
        margin-left: 0;
        margin-bottom: 1rem;
      }
    }
    .form-group:first-of-type {
      padding-top: 2rem;
    }
    div.radio {
      margin-bottom: 0;
      font-size: 1.4rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 0;
    }
    .field-boolean {
      margin-top: -5px;
      padding-left: 0;
    }
  }

  .summary.scroll-area,
  .summary.scroll-area > .scroll-area {
    padding: 0px 0px;
  }

  table.summary-table > tbody > tr:first-child > td {
    border-top: none;
  }

  .subfield_inverted {
    @include subfield_inverted;
  }

  .buttonWrapper {
    width: 100%;
    button:first-child {
      float: left;
    }
    button:last-child {
      float: right;
    }
  }

  .form-group.field-error {
    margin-left: -5px;
    padding-left: 5px;
    border-left: 8px solid $red-error;

    legend {
      color: $red-error;
    }

    &.has-error {
      .checkbox,
      .checkbox-inline,
      .radio,
      .radio-inline,
      .checkbox label,
      .checkbox-inline label,
      .radio label,
      .radio-inline label {
        color: unset;
      }

      .control-label::before {
        font-family: 'Glyphicons Halflings';
        font-weight: 900;
        content: '\e101';
        font-size: 1.2rem;
        margin-right: 5px;
      }
    }

    .bs-callout {
      margin-left: $left-pad;
    }
  }
  .form-error-summary {
    display: none; // display: none;
  }
  .error {
    color: $red-error;
  }
  .error-label {
    font-weight: 700;
  }
  div.checkbox,
  div.radio {
    padding: 2rem 1rem 2rem $left-pad; // 10px 10px 10px 0;
    //border: 1px solid black;
    min-width: 27px;

    justify-content: center;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    background-color: $gray-bg;

    label {
      padding: 0;
      width: 100%;
    }

    > label span {
      font-family: 'Open Sans';
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 1.6rem;
      line-height: 1.8rem;
      color: $text-dark;
    }

    > label span span {
      min-height: 20px;
      //padding: 10px 20px 10px 10px;
      margin-bottom: 0;
      font-weight: 400;
      cursor: pointer;
      display: flex;
    }
  }

  div.secondary-radio div.radio {
    padding: 1rem 1rem 1rem 0;
    margin-bottom: 0;
    font-size: 1.4rem;
  }

  $margin-from-dot: 5px;
  $negative-offset-from-dot: 19px;
  $extra-label-padding: 20px;
  $extra-label-padding-sm: 2px;
  $checkbox-width: 18px;

  .checkbox,
  .radio {
    padding-left: 0px;
    min-width: 27px;
  }

  .checkbox {
    .subfields.other {
      margin-top: 2rem;
      width: 100%;
      font-size: 1.4rem;
      font-style: italic;
      padding-left: calc(
        #{$margin-from-dot+ $extra-label-padding+ $checkbox-width+2}
      );
      display: block;
    }
  }
  input[type='checkbox'],
  input[type='radio'] {
    display: none;
  }
  .checkbox > label::before,
  .radio::before,
  .radio-inline::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    border: 1px solid #888;
    border-radius: 2px;
    background: radial-gradient(white, #eee);
    margin-right: $margin-from-dot; //offset to label
    flex-shrink: 0;
  }

  .checkbox > label::before {
    width: $checkbox-width;
    height: $checkbox-width;
    margin-right: calc(
      #{$margin-from-dot+ $extra-label-padding}
    ); //offset to label
  }

  .subcheckbox {
    .checkbox > label::before {
      margin-right: calc(
        #{$margin-from-dot+ $extra-label-padding-sm}
      ); //offset to label
    }

    input[type='checkbox']:checked + label::before,
    input[type='checkbox']:checked + span::before {
      margin-left: calc(-4px - #{$checkbox-width+ + $extra-label-padding-sm});
      margin-right: calc(8px + #{$extra-label-padding-sm});
    }

    .radio input[type='radio']:checked + label::before,
    .radio input[type='radio']:checked + span::before,
    .radio-inline input[type='radio']:checked + label::before,
    .radio-inline input[type='radio']:checked + span::before {
      margin-left: calc(
        (-1 * #{$negative-offset-from-dot + $extra-label-padding-sm})
      );
      margin-right: calc(8px + #{$extra-label-padding-sm});
    }

    .radio input[type='radio'] + label,
    .radio input[type='radio'] + span,
    .radio-inline input[type='radio'] + label,
    .radio-inline input[type='radio'] + span {
      padding-left: calc(
        #{$margin-from-dot + $negative-offset-from-dot+ $extra-label-padding-sm}
      );
    }
  }

  .radio::before,
  .radio-inline::before {
    border-radius: 50%;
  }

  input[type='checkbox']:checked + label::before,
  input[type='checkbox']:checked + span::before {
    content: url('../../../assets/checkmark.svg');

    width: calc(-2px + #{$checkbox-width});
    height: calc(-2px + #{$checkbox-width});
    padding-top: 0;
    padding-left: 1px;
    background-color: #0084ff;
    color: #fff;
    //color: $primary-action-color;
    font-size: 10px;
    margin-left: -19px;
    margin-left: calc(-4px - #{$checkbox-width+ + $extra-label-padding});
    top: 0px;

    position: relative;
    margin-right: calc(8px + #{$extra-label-padding});
    font-weight: bolder;
  }
  .radio input[type='radio']:checked + label::before,
  .radio input[type='radio']:checked + span::before,
  .radio-inline input[type='radio']:checked + label::before,
  .radio-inline input[type='radio']:checked + span::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    border-radius: 50%;

    margin-left: calc(
      (-1 * #{$negative-offset-from-dot + $extra-label-padding})
    );
    margin-right: calc(8px + #{$extra-label-padding});
    flex-shrink: 0;
    background: $primary-blue;
  }
  .radio input[type='radio'] + label,
  .radio input[type='radio'] + span,
  .radio-inline input[type='radio'] + label,
  .radio-inline input[type='radio'] + span {
    padding-left: calc(
      #{$margin-from-dot + $negative-offset-from-dot+ $extra-label-padding}
    );
    margin-left: calc(-1 * #{$margin-from-dot + $negative-offset-from-dot});
  }

  .checkbox label,
  .radio label {
    padding-left: 0px;
  }

  .checkbox [type='checkbox'] + span {
    // AG padding-left: 24px;
  }

  .radio input[type='radio'] + span {
    // AG padding-left: 24px;
  }

  .checkbox + .checkbox,
  .radio + .radio {
    margin-top: -6px;
  }

  .checkbox label {
    display: flex;
    align-items: center;
  }

  input[type='text'],
  input[type='number'] {
    min-height: 40px;
    border: 1px solid $primary-blue;
    border-radius: 0;
    padding: 10px 20px;
    font-size: 1.6rem;
  }

  select {
    height: 40px;
    border: 1px solid $primary-blue !important;
    border-radius: 0;
    padding: 0.5rem 2.5rem 0.5rem 1rem;
    box-shadow: none;
    border-radius: 0;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    font-size: 1.6rem;
    background-image: url('../../../assets/select_arrow.svg');
    background-repeat: no-repeat, repeat;
    background-position: right 0.4rem top 50%, 0 0;
    background-size: 0.65em auto, 100%;
  }
  /* select::-ms-expand {
      display: none;
    }
    select:hover {
      border-color: #888;
    }
    select:focus {
      border-color: #aaa;
      box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
      box-shadow: 0 0 0 3px -moz-mac-focusring;
      color: #222;
      outline: none;
    }
    select option {
      font-weight:normal;
    }*/

  ///////overrides

  .show_fieldSet {
    legend {
      display: inline-block !important;
      font-size: 1.6rem;
    }
    .form-group.field-boolean {
      margin-bottom: 0;
      .checkbox {
        margin-bottom: 2px;
        margin-top: 0px;
      }
    }
    .form-group.field-string {
      margin-bottom: 10px;
    }
  }

  //contactinfo survey
  .contact {
    legend {
      display: none;
    }
    .birthdate > ul {
      display: flex;

      select {
        flex-basis: 33%;
      }
    }

    .gender {
      clear: both;
    }

    .address1 {
      margin-bottom: 1rem;
    }
    .form-group.address2,
    .form-group.city {
      margin-bottom: 1rem;
    }

    .form-group.address2,
    .form-group.city,
    .form-group.state,
    .form-group.zip {
      label {
        display: none;
      }
    }
    > .fieldset {
      width: 100%;

      .zip {
        float: right;
        width: 60%;
        padding-left: 5px;
        &.form-group.field-error {
          margin-left: 0;
          padding-left: 15px;
          border-left: none;
        }
      }
      .state {
        float: left;
        width: 40%;
      }
    }
  }

  //-------------------------------------            covid1 survey3
  .symptoms,
  .hospitalization_treatment,
  .diseases_history_cont,
  .family_diseases,
  .health-conditions,
  .women .complications,
  .countries {
    legend {
      @include control_label;
      display: inline-block;
      padding-right: 1rem;
    }
    .form-group.field-boolean {
      margin-bottom: 0;
      > .checkbox {
        margin-top: -3px;
        margin-bottom: 5px;
      }
    }
    .form-group.field-string {
      margin-bottom: 5px;
    }
    .form-group.field-string,
    .form-group.field-number {
      @include subfield_inverted;
      input {
        width: 50%;
      }
      &.wide {
        input {
          width: 100%;
        }
      }
    }
    .form-group.subcheckbox {
      @include subfield_inverted;
      margin-left: -1.5rem;
      margin-top: -2rem;
      .checkbox {
        padding-bottom: 0;
        span {
          @include label_level2;
        }
      }
    }
  }

  .symptoms {
    .form-group.subcheckbox {
      margin-top: -0.75rem;
      .control-label {
        padding-left: 1.2rem;
      }
    }
  }
  .symptom-dates {
    .field-description {
      margin-left: 1.5rem;
    }
    .list-inline {
      display: flex;
    }
    .form-group.subcheckbox {
      @include subfield_inverted;
      margin-left: 0;
      padding-left: 0;
      margin-top: -4rem;
      margin-top: -2rem;
      .checkbox {
        padding-bottom: 0;
        span {
          @include label_level2;
        }
      }
    }
  }

  .symptoms2 {
    legend {
      @include control_label;
      display: inline-block;
      max-width: 100%;
    }
    .form-group.field-boolean {
      margin-bottom: 0;
      > .checkbox {
        margin-top: -3px;
        margin-bottom: 5px;
      }
    }
    .test_result {
      background-color: blue;
      .radio {
        padding-top: 0.1rem;
        padding-bottom: -1rem;
        label {
          margin-bottom: 0;
        }
      }
    }
  }

  .covid2 {
    &_covid1 {
      .countries {
        .row.array-item-list {
          margin-left: 0;
          margin-right: 0;
          .array-item {
            overflow: hidden;
          }
        }
        .country-name {
          select {
            width: 97%;
          }
        }
        .array-item-add {
          margin: 0 1rem 0 1rem;
          padding: 0 4rem 0 3.5rem;

          text-align: left;

          .btn-add {
            i {
              background-color: $primary-blue;
            }
            text-align: left;
            background-color: $primary-blue;
            color: #fff;
            padding: 0.7rem 1rem;
            font-size: 1.6rem;

            &::after {
              content: 'Add Country';
            }
          }
        }
        .form-group {
          background-color: transparent;
          padding-bottom: 1rem;
          padding-left: 2.5rem;

          &.field-object {
            margin-bottom: 0.5rem;
          }
        }
      }
      .household_size,
      .people_diagnosed {
        .fieldset {
          width: 100%;
        }
        .field-number {
          display: flex;
          flex-direction: row-reverse;
          padding-left: 1.5rem;
          margin-bottom: 1.5rem;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
          input {
            width: 30%;
            flex-shrink: 0;
          }
          label {
            margin-bottom: 0;
            font-size: 1.6rem;
            font-weight: normal;
          }
        }
        .field-boolean {
          clear: left;
          margin-right: 5px;
          .checkbox {
            border: none;
            background-color: transparent;
          }

          .checkbox > label::before {
            margin-left: 0 !important;
          }
        }
      }

      
    }
    &_test_location {
      .field-description {
        margin-left: 1.5rem;
        margin-right: 1rem;
      }
    }
  }

  /* -------------------------------------    health history */
  .health-history {
    .height {
      margin-bottom: 0rem;
      > .fieldset {
        display: flex;
        flex-wrap: wrap;
        legend {
          font-size: 1.6rem;
          width: 100%;
          margin-bottom: 5px;
        }
        label.control-label {
          display: none;
        }
        .form-group {
          width: 45%;
          margin-bottom: 0px;

          &.field-error .bs-callout {
            margin-left: 0;
            font-size: 1.4rem;
          }
        }
        .form-group:first-of-type {
          margin-right: 5px;
          margin-bottom: 0;
        }

        .field-error.form-group:not(:first-of-type) {
          border-left: 0px;
        }
      }
    }

    .weight {
      input {
        width: 30%;
      }
      &.form-group.field-error .bs-callout {
        margin-left: 0;
        font-size: 1.4rem;
      }
    }

    .bp_medication {
      @include subfield_inverted;
    }

    .women {
      .miscarriage_number {
        @include subfield_inverted;
        margin-top: -2rem;
      }
      #root_women > legend {
        display: none;
      }
    }
  }

  /* -------------------------------------    postLab survey */
  #root_postLab_longterm_impact__title {
    @include control_label;
  }

  @media only screen and (max-width: 768px) {
    div.checkbox,
    div.radio {
      padding: 1rem 1rem 1rem 1.5rem;
    }
    label.control-label {
      margin-left: 1.5rem;
      margin-right: 1rem;
    }
    .subfields .form-group {
      padding-left: 1rem;
    }
  }
}

//one-off withdrawal from
.withdrawal-form .theme-crc .SRC-ReactJsonForm {
  padding-top: 1.8rem;
  background: #f5f5f5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  .form-group {
    margin-bottom: 0;
    div.checkbox:last-child {
      margin-bottom: 0;
    }
  }
  margin-bottom: 2rem;
}
