.MuiToggleButtonGroup-root.verticalToggle {
  display: block;
  background-color: transparent;
  .MuiToggleButtonGroup-grouped {
    display: block;
    width: 100%;
    margin-bottom: 8px;
    background: transparent;
    border-color: #000000;
    color: #000;
    text-align: left;
    padding-left: 2em;
  }

  .MuiToggleButton-root:hover,
  .MuiToggleButton-root.Mui-selected:hover {
    border-width: 2px;
  }

  .MuiToggleButton-root.Mui-selected {
    color: #fff;
    background-color: #000;
  }
  .MuiToggleButtonGroup-grouped:not(:last-child) {
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
  }
  .MuiToggleButtonGroup-grouped:not(:first-child) {
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;

    border-left-style: solid;
    border-color: #000000;
  }
}

.MuiTabs-root {
  background-color: #efefef;
  .MuiTab-root {
    min-width: 110px;
  }

  .MuiTab-textColorPrimary {
    &.Mui-selected {
    }
  }
}
